<template>
  <div class="verified">
    <van-cell title="个人信息"  is-link @click="$router.push(`/personal_info`)" />
    <van-cell title="企业信息"
              is-link
              :value="bind_status"
              :class="`title-${this.bind_color}`"
              @click="toCompany" />
    <van-cell title="个人实名"
              is-link
              :class="`title-${userInfo.verifyStatus}`"
              :value="verify_status"
              @click="toVerified"/>
    <van-cell title="企业认证"
              v-if="company && isCreater"
              is-link
              :class="`title-${company.verifyStatus}`"
              :value="company_status"
              @click="toCompany_ver"
              />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { getH5CurrentCompany,getIsCreater} from "@/api/res-company";
import {Toast} from "vant";
export default {
  name: 'index',
  data () {
    return {
      company:'',
      isCreater:true,
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    verify_status(){
      let map={'1':'未实名','2':'实名中','3':'已实名','4':'实名失败'}
      return map[this.userInfo.verifyStatus]
    },
    company_status(){
      let map={'1':'未认证','2':'认证中','3':'已认证','4':'认证失败'}
      return map[this.company.verifyStatus]
    },
    bind_status(){
      if(this.company && !this.isCreater){
        let map=new Map([['2','绑定成功'],['1','绑定中'],['3','绑定失败']])
        return map.get(this.company.joinCompanyVerifyStatus )
      }
    },
    bind_color(){
      if(this.company && !this.isCreater){
        let map=new Map([['2','3'],['1','2'],['3','4']])
        return map.get(this.company.joinCompanyVerifyStatus)
      }

    },
  },
  created () {
    document.title = '账号设置'
    this.loadCompany();
  },



  methods:{
    toVerified(){
      if(this.userInfo.verifyStatus==='1'){
        this.$router.push(`/verified_personal`)
      }else {
        this.$router.push(`/personal_result`)
      }
    },

    async loadCompany() {

      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });
     await getH5CurrentCompany().then((company) => {
        this.company = company;
      });

    if(this.company){
      await getIsCreater(this.company.id).then((res) => {
        this.isCreater = res;
      });
    }


      Toast.clear()
    },




    toCompany(){
      if(this.company && this.isCreater){
        this.$router.push('/verified/company_info')
      }else if(!this.isCreater && this.company){
        this.$router.push({
          path: '/verified/company_result',
          query: { type:'association' },
        })
      }else {
        this.$router.push('/verified/company')
      }
    },

    toCompany_ver(){
     /*  this.$router.push({
        path: '/verified/company_result',
        query: { type:'verified' },
      }) */


      if(this.company.verifyStatus==='1'){
        this.$router.push(`/verified/certification_company`)
      }else {
        this.$router.push({
          path: '/verified/company_result',
          query: { type:'verified' },
        })
      }
    }

  }
}
</script>

<style scoped lang="scss">
.verified{
  width: 100vw;
  min-height: calc(100vh - 56px);
  background: #F6F6F6;
  padding: 16px;
  :nth-child(1){
    border-radius: 4px 4px 0 0 ;
  }
  :nth-child(3){
    border-radius:  0 0 4px 4px;
  }
  ::v-deep{
    .van-cell{
      height: 57px;
      display: flex;
      align-items: center;
      :nth-child(1){
        display: flex;
        align-items: center;
        >span{
          font-size: 16px;
          color: #333333;
        }
      }
      :nth-child(2){
        display: flex;
        align-items: center;
        justify-content: flex-end;
        >span{
          font-size: 16px;
          color: #999999;
        }
      }
    }
  }
  .title-2::v-deep{
    span{
      color: #FAAD29;
    }
  }

  .title-2::v-deep{
    .van-cell__value{
      span{
        color: #FAAD29 !important;
      }
    }

  }

  .title-3::v-deep{
    .van-cell__value{
      span{
        color: #50D4AB !important;
      }
    }
  }

  .title-4::v-deep{
    .van-cell__value{
      span{
        color: #FC4D4F !important;
      }
    }
  }

}
</style>
